import {
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { BACKEND_URL, userContext } from "../../Context";
import "./FeedbackCard.css";
import FeedbackMessage from "../Feedback/FeedbackMessage";
import { FeedbackApi, PhaseApi } from "../../Utils";
import ResultToast from "../ResultToast";
import { siteContext } from "../Utils/SiteContext";
import { CleaningServices } from "@mui/icons-material";

function FeedbackCard({ recruit }) {
  const { user, successToast, errorToast, toastUpdateFlag } =
    useContext(siteContext);
  const [feedbackList, setFeedbackList] = useState([]);
  const [newFeedback, setNewFeedback] = useState("");
  const [questions, setQuestions] = useState("");
  const [phases, setPhases] = useState([]);
  const [ selectedPhase, setSelectedPhase ] = useState();
  const [ currentPhase, setCurrentPhase ] = useState();

  const deleteFeedback = async (feedback) => {
    const req = await FeedbackApi.deleteById(feedback.id);
    if (req.status == 200) {
      successToast("Feedback deleted.");
    } else {
      errorToast("Failed to delete feedback.");
      console.error(await req.text());
    }
  };

  const submitReply = async (feedback, message) => {
    const req = await FeedbackApi.createFeedback(
      feedback,
      user,
      recruit,
      currentPhase,
      message
    );
    if (req.status == 200) {
      successToast("Reply sent.");
    } else {
      errorToast("Failed to send reply.");
      console.error(await req.text());
    }
  };

  const submitNewFeedback = async () => {
    const req = await FeedbackApi.createFeedback(
      null,
      user,
      recruit,
      selectedPhase,
      newFeedback
    );

    if (req.status == 200) {
      successToast("Feedback posted.");
    } else {
      errorToast("Failed to post feedback.");
      console.error(await req.text());
    }

    setNewFeedback("");
  };

  useEffect(() => {
    const loadPhases = async () => {
      const list = await PhaseApi.getAll();
      const current = await PhaseApi.getCurrentPhase();
      setPhases(list);
      setCurrentPhase(current);
    };

    const loadFeedbackTree = async () => {
      try {
        await loadPhases();
        const list = await FeedbackApi.getUserFeedback(recruit);
        setFeedbackList(list);
      } catch (error) {
        console.error(error);
      }
    };

    loadFeedbackTree();
  }, [recruit, toastUpdateFlag]);

  return (
    <Card
      sx={{ width: "60%", minHeight: "625px" }}
      className="view-user-feedback"
    >
      <CardContent>
        <Typography variant="h5" component="div">
          <strong style={{color: 'red'}}>ATENTIE!</strong> Nu puneti emoji-uri in feedback 😔
        </Typography>
        <Divider style={{ margin: '12px' }}/>
        <Typography variant="h4" component="div">
          Extra
        </Typography>
        <p>
          <Typography variant="h6" sx={{ fontSize: "20px" }}>
            1. Ai anumite cerințe speciale de care să ținem cont?
          </Typography>
          {recruit.recruit.specialNeeds
            ? recruit.recruit.specialNeeds
            : "Unanswered"}
        </p>
        <p>
          <Typography variant="h6" sx={{ fontSize: "20px" }}>
            2. Să ne imaginăm că ești nevoit să te transformi într-un personaj
            al poveștilor din copilărie. Cine ai fi și de ce? Ce ai face diferit
            față de acesta și cum ai schimba cursul poveștii pe care ai ales-o?
          </Typography>
          {recruit.recruit.specificQuestion
            ? recruit.recruit.specificQuestion
            : "Unanswered"}
        </p>

        <Divider sx={{ margin: 2 }} />

        <Typography variant="h4" component="div">
          Recruit feedback
        </Typography>

        <div id="new-feedback-form" style={{ width: "100%" }}>
          <FormControl sx={{ m: 1, width: 300, gap: '16px' }}>
            <InputLabel id="quick-feedback-phase">Phase</InputLabel>
            <Select
                reqiured
                labelId="quick-feedback-phase"
                value={selectedPhase}
                defaultValue={currentPhase?.id ? currentPhase.id : 'none'}
                onChange={(e) => setSelectedPhase(e.target.value)}
                input={<OutlinedInput label="Phase" />}
                MenuProps={{ PaperProps: { style: { maxHeight: 48 * 4.5 + 8, width: 250 } }}}
            >
              <MenuItem key='none' value='none'>
                  None
              </MenuItem>
              {
                  phases.map((phase) => (
                      <MenuItem key={phase.id} value={phase.id}>
                          {
                              (currentPhase && phase.id === currentPhase.id) 
                                  ? `Current: ${phase.name}` 
                                  : phase.name
                          }
                      </MenuItem>
                  ))
              }
            </Select>
          </FormControl>
          <TextField
            required
            type="text"
            variant="outlined"
            label="Feedback"
            multiline
            minRows={10}
            value={newFeedback.replace(/(\\n)/g, "\n")}
            onChange={(e) => setNewFeedback(e.target.value)}
            style={{ width: "100%" }}
          />

          <Button variant="contained" onClick={submitNewFeedback}>
            Submit
          </Button>
        </div>

        <Divider />

        <div id="feedback-cards">
          {feedbackList.map((elem) => (
            <FeedbackMessage
              key={elem.id}
              feedback={elem}
              recruit={recruit}
              handleDelete={deleteFeedback}
              handleReply={submitReply}
              questions={
                phases.find((phase) => phase.id == elem.phaseId)?.questions
              }
            />
          ))}
        </div>
      </CardContent>
    </Card>
  );
}

export default FeedbackCard;
