import { useContext, useEffect, useState } from "react";
import { BACKEND_URL, userContext } from "../Context";
import Navbar from "../components/Navigation/Navbar";
import UsersList from "../components/UsersList";
import { Box, Button, FormControl, FormControlLabel, InputLabel, MenuItem, Modal, Paper, Select, Switch, TextField, Typography } from "@mui/material";
import FormModal from "../components/Forms/FormModal";
import NewUserForm from "../components/Forms/NewUserForm";
import { UserApi, VoteApi } from "../Utils";
import { siteContext } from "../components/Utils/SiteContext";
import "./Decisions.css";
import "./Users.css";
import UserPreview from "../components/UserInfo/UserPreview";

function Decisions() {
    const { user, toastUpdateFlag } = useContext(siteContext);
    const [ recruits, setRecruits ] = useState([]);
    const [ dept, setDept ] = useState(localStorage.getItem(`viewed-dept`));
    const [ option, setOption ] = useState(localStorage.getItem(`viewed-option`));
    const [ hideTaken, setHideTaken ] = useState(localStorage.getItem(`hide-taken`));
    const [ previewedUser, setPreviewedUser ] = useState();

    const loadRecruits = async () => {
        const list = await UserApi.getAllRecruits(false);

        if(dept) {
            const firstFilter = list.filter(
                (recruit) => 
                    (option) 
                        ? (
                            (option == 1) 
                                ? recruit?.recruit?.firstOption == dept 
                                : recruit?.recruit?.secondOption == dept
                        )
                        : recruit?.recruit?.firstOption == dept
            );

            if(hideTaken) {
                console.table(firstFilter);
                setRecruits(firstFilter.filter(
                    (recruit) => !(recruit?.recruit?.firstOptionDecision == 'verde' &&
                                recruit?.recruit?.firstOption != dept)
                ));
            }
            else
                setRecruits(firstFilter);
        }
        else
            setRecruits(list);
    };

    const changeDept = async (e) => {
        setDept(e.target.value);
        localStorage.setItem(`viewed-dept`, e.target.value);
    };

    useEffect(() => { loadRecruits(); }, [ toastUpdateFlag, dept, option, hideTaken ]);
    useEffect(() => { 
        if(recruits.length === 0)
            loadRecruits();
    }, []);

    const usersTable = (previewedUser)
        ? <>
            <div id="split-page">
                <UsersList 
                    title="Recruits" 
                    list={recruits} 
                    showVoteOptions={option ? option : 1}
                    setPreviewedUser={(user) => {
                        if(previewedUser && user.id == previewedUser.id)
                            setPreviewedUser();
                        else
                            setPreviewedUser(user);
                    }}
                />
                <div id="user-preview">
                    <UserPreview user={previewedUser} /> 
                </div>    
            </div>
        </>
        : <>
            <UsersList 
                title="Recruits" 
                list={recruits} 
                showVoteOptions={option ? option : 1}
                setPreviewedUser={(user) => setPreviewedUser(user)}
            />
        </>;

    return <>
        <Navbar />
        <div id="users-page">
            <FormControl style={{ minWidth: '30%'}}>
                <InputLabel id="select-dept">Department</InputLabel>
                <Select
                    labelId="select-dept"
                    id="select-dept"
                    value={dept}
                    defaultValue={"IT front-end"}
                    label="Department"
                    onChange={changeDept}
                >
                    <MenuItem value="EDU">EDU</MenuItem>
                    <MenuItem value="FR">FR</MenuItem>
                    <MenuItem value="HR">HR</MenuItem>
                    <MenuItem value="I&P">I&P</MenuItem>
                    <MenuItem value="IT front-end">IT front-end</MenuItem>
                    <MenuItem value="IT back-end">IT back-end</MenuItem>

                </Select>

                <FormControlLabel 
                    control={
                        <Switch 
                            checked={option == 2} 
                            onChange={(e) => {
                                setOption(e.target.checked ? 2 : 1);
                                localStorage.setItem(`viewed-option`, e.target.checked ? 2 : 1);
                            }}
                        />
                    } 
                    label={option == 1 ? "Viewing users with first option" : "Viewing users with second option"} 
                />

                <FormControlLabel
                    control={
                        <Switch
                            checked={hideTaken}
                            onChange={(e) => {
                                setHideTaken(e.target.checked);
                                localStorage.setItem(`hide-taken`, e.target.checked);
                            }}
                        />
                    }
                    label={"Hide already taken"}
                />
            </FormControl>
            
            {usersTable}
        </div>
    </>;
}

export default Decisions;