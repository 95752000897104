import { Chip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { useContext } from "react";
import { siteContext } from "./SiteContext";
import { UserApi, VoteApi } from "../../Utils";

function VoteButton({ params, votes, value, color, icon, option }) {
    const { user, successToast, errorToast, requireConfirm } = useContext(siteContext);
    const similarVotes = votes.filter((vote) => vote.value == value);
    const userVote = similarVotes.find((vote) => {
        return vote.recruitId == params.id &&
            vote.voterId == user.id &&
            vote.value == value;
    });

    return (
        <GridActionsCellItem
            icon={
                <Chip 
                    icon={icon} 
                    color={userVote ? color : ""}
                    label={`${similarVotes.length}`} 
                />
            }
            label="Cast vote"
            disabled={userVote ? true : false}
            onClick={async () => {
                if(
                    user?.rank == 'admin' && 
                    (await requireConfirm({
                        title: "ADMINISTRATOR: Decizie finală?",
                        message: "Vrei ca această decizie să devină cea finală?",
                        confirmText: "Da",
                        refuseText: "Nu, vot simplu"
                    }))
                ) {
                    await UserApi.updateUser(params.id,
                        (option == 1)
                            ? { firstOptionDecision: value }
                            : { secondOptionDecision: value }
                    );
                }

                const req = await VoteApi.voteForRecruit(user, params.id, option, value);
                if(req.status === 200)
                    successToast(`Vote cast!`);
                else
                    errorToast(`Failed to cast vote.`);
            }}
        />
    );
}

export default VoteButton;