import { Box, Chip, Paper, styled, Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridToolbar, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Delete, Edit, Help, RemoveRedEye, ThumbDownSharp, ThumbUpSharp } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { PostLog, UserApi, VoteApi } from "../Utils";
import { siteContext } from "./Utils/SiteContext";
import VoteButton from "./Utils/VoteButton";

function QuickSearchToolbar() {
    const { user } = useContext(siteContext);

    if(user.rank === 'admin')
        return <GridToolbar />;

    return (
        <Box
          sx={{
            p: 0.5,
            pb: 0,
          }}
        >
          <GridToolbarQuickFilter />
        </Box>
    );
}

function UsersList({ title, list, showAccountType, showDeptOptions, showVoteOptions, setPreviewedUser, hideUserId, extraColumns }) {
    const { user, successToast, errorToast, cookies, setCookie } = useContext(siteContext);
    const [ pagination, setPagination ] = useState();

    useEffect(() => {
        if(!pagination) {
            const saved = JSON.parse(localStorage.getItem(title));
            setPagination(saved ? saved : {
                pageSize: 100,
                page: 0
            });
        }
    }, [pagination]);

    const navigate = useNavigate();

    const deleteUser = async (id) => {
        const deletedUser = await UserApi.getUser(id);
        
        const res = await UserApi.deleteUser(id);
        if(res.status == 200) {
            PostLog(
                user, 
                "delete_user", 
                `User ${deletedUser.firstName} ${deletedUser.lastName} deleted by ${user.firstName} ${user.lastName}.`
            );

            successToast(`User "${deletedUser.firstName} ${deletedUser.lastName}" deleted.`);
        } else {
            errorToast(`Failed to delete user "${deletedUser.firstName} ${deletedUser.lastName}".`);
        }
    };

    const actionButtons = { 
        headerName: 'Actions', 
        type: 'actions', 
        field: 'actions',
        flex: 1,
        minWidth: 120,
        getActions: (params) => [
            <GridActionsCellItem
                icon={<RemoveRedEye />}
                label="View user"
                onClick={() => { navigate(`/viewUser?id=${params.id}`) }}
            />,
            <GridActionsCellItem
                icon={<Edit />}
                label="Edit user"
            />,
            <GridActionsCellItem
                icon={<Delete />}
                color="error"
                label="Delete user"
                onClick={() => { 
                    if(user.rank == 'admin') {
                        deleteUser(params.id);
                    } else {
                        errorToast("Insufficient permissions.");
                    }
                }}
            />
        ]
    };

    const makeVoteButtons = (option) => {
        return {
            headerName: 'Vote',
            type: 'actions',
            field: `vote-${option}`,
            flex: 1,
            minWidth: 200,
            getActions: (params) => {
                const votes = (showVoteOptions == 1) ? params.row.votesFirstOpt : params.row.votesSecondOpt;
                return [
                    <VoteButton 
                        params={params} 
                        option={option} 
                        votes={votes} 
                        value="verde" 
                        color="success" 
                        icon={<ThumbUpSharp color="success" />}
                    />,
                    <VoteButton
                        params={params}
                        option={option}
                        votes={votes}
                        value="galben"
                        color="warning"
                        icon={<Help color="warning" />}
                    />,
                    <VoteButton
                        params={params}
                        option={option}
                        votes={votes}
                        value="rosu"
                        color="error"
                        icon={<ThumbDownSharp color="error" />}
                    />
                ]
            }
        }
    };

    const voteRowColor = (showVoteOptions)
        ? (params) => {
            const recruit = params.row?.recruit;
            if(showVoteOptions == 1 && recruit?.firstOptionDecision) {
                return `vote-row-${recruit?.firstOptionDecision}`;
            } else if(showVoteOptions == 2 && recruit?.secondOptionDecision) {
                return `vote-row-${recruit?.secondOptionDecision}`;
            } else 
                return ``;
        } 
        : null;

    const previewButton = {
        headerName: 'Preview',
        type: 'actions',
        field: `preview-user`,
        flex: 1,
        maxWidth: 120,
        getActions: (params) => [
            <GridActionsCellItem
                icon={<RemoveRedEye />}
                label="Preview user"
                onClick={() => { if(setPreviewedUser) setPreviewedUser(params.row); } }
            />
        ]
    };

    const baseColumns = [
        { headerName: 'ID', field: 'id', flex: 1, maxWidth: 60, headerAlign: 'center' },
        { headerName: 'First name', field: 'firstName', flex: 1, minWidth: 160, hideable: false, headerAlign: 'center' },
        { headerName: 'Last name', field: 'lastName', flex: 1, minWidth: 160, hideable: false, headerAlign: 'center' },
        { headerName: 'Email', field: 'email', flex: 1, minWidth: 300, hideable: true, headerAlign: 'center' },
        { 
            headerName: 'Phone', 
            field: 'phone', 
            renderCell: (params) =>
                (!params.value)
                    ? 'Not set'
                    : params.value,
            flex: 1,
            minWidth: 160,
            headerAlign: 'center'
        },
        {
            headerName: 'Account type',
            field: 'rank',
            renderCell: (params) =>
                (params.value == 'admin')
                    ? 'Administrator'
                    : (params.value == 'siscot')
                        ? 'Member'
                        : (params.value == 'recruit') 
                            ? 'Recruit'
                            : 'Inactive',
            flex: 1,
            minWidth: 120,
            headerAlign: 'center'
        },
        {
            headerName: 'First option',
            field: 'firstOption',
            valueGetter: (params, row) => 
                (row.recruit && row.recruit.firstOption)
                    ? row.recruit.firstOption
                    : 'Not set',
            flex: 1,
            headerAlign: 'center',
            minWidth: 140
        },
        {
            headerName: 'Second option',
            field: 'secondOption',
            valueGetter: (params, row) => 
                (row.recruit && row.recruit.secondOption)
                    ? row.recruit.secondOption
                    : 'Not set',
            flex: 1,
            headerAlign: 'center',
            minWidth: 140
        },
    ];

    let columns = (showVoteOptions)
                        ? [ ...baseColumns, makeVoteButtons(showVoteOptions), previewButton ]
                        : baseColumns;

    columns = (extraColumns)
                    ? [ ...columns, ...extraColumns ] 
                    : [ ...columns ];

    columns = (showVoteOptions)
                ? [ ...columns ]
                : [ ...columns, actionButtons ];

    if(!pagination)
        return <></>;

    const makeBgColor = (bgColor, fgColor) => {
        return {
            backgroundColor: bgColor,
            color: fgColor,
            '&:hover': {
                backgroundColor: bgColor,
                color: fgColor
            }
        };
    }

    const StyledDataGrid = styled(DataGrid)((data) => ({
        '.vote-row-verde': makeBgColor('#9ff299', 'black'),
        '.vote-row-galben': makeBgColor('#b87d00', 'black'),
        '.vote-row-rosu': makeBgColor('#b53131', 'black')
    }));

    return (
        <Paper sx={{ width: '90%', minHeight: '70vh' }} id="user-list-paper" >
            <StyledDataGrid 
                sx={{ border: 0 }}
                columns={columns}
                rows={list}
                initialState={{ 
                    pagination: { page: 0, pageSize: 5 },
                    columns: {
                        columnVisibilityModel: {
                            firstOption: (showDeptOptions) ? true : false,
                            secondOption: (showDeptOptions) ? true : false,
                            rank: (showAccountType) ? true : false,
                            id: (hideUserId) ? false : true,
                            email: (showVoteOptions) ? false : true,
                            phone: (showVoteOptions) ? false : true
                        }
                    }
                }}
                disableRowSelectionOnClick
                disableColumnSelector
                autosizeOptions={{
                    includeOutliers: true,
                    includeHeaders: false
                }}
                autosizeOnMount
                ignoreDiacritics
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        printOptions: { disableToolbarButton: true },
                        csvOptions: { disableToolbarButton: (user.rank !== 'admin') }
                    }
                }}
                density="comfortable"
                disableDensitySelector
                disableColumnFilter
                paginationModel={pagination}
                onPaginationModelChange={(model) => {
                    localStorage.setItem(title, JSON.stringify(model));
                    setPagination(model);
                }}
                getRowClassName={voteRowColor}
            />
        </Paper>
    );
}

export default UsersList;