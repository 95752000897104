import { useContext, useEffect, useState } from "react";
import { BACKEND_URL, userContext } from "../Context";
import Navbar from "../components/Navigation/Navbar";
import "./Users.css";
import UsersList from "../components/UsersList";
import { Box, Button, Modal, Paper, TextField, Typography } from "@mui/material";
import FormModal from "../components/Forms/FormModal";
import NewUserForm from "../components/Forms/NewUserForm";
import { UserApi } from "../Utils";
import { siteContext } from "../components/Utils/SiteContext";

function Users() {
    const { user, toastUpdateFlag } = useContext(siteContext);
    const [ users, setUsers ] = useState([]);
    const [ isCreating, setIsCreating ] = useState(false);
    const [ userStats, setUserStats ] = useState({});

    const loadUserList = async () => {
        const list = await UserApi.getAllUsers();
        setUsers(list);
    };

    useEffect(() => { loadUserList(); }, [ toastUpdateFlag ]);
    useEffect(() => { if(users.length === 0) loadUserList(); }, []);

    useEffect(() => {
        const stats = { users: 0, recruits: 0, gaveUp: 0 };
        for(const user of users) {
            if(user.rank == 'siscot' || user.rank == 'admin')
                stats.users++;
            else if(user.rank == 'recruit')
                stats.recruits++;
            else if(user.rank == 'recruit_lasat')
                stats.gaveUp++;
        }

        setUserStats(stats);
    }, [ users ]);

    return <>
        <Navbar />
        <div id="users-page">
            <div id="users-stats">
                <Typography variant="h4">User Stats</Typography>
                <Typography variant="h5">Site users: {userStats.users}</Typography>
                <Typography variant="h5">Recruits: {userStats.recruits} (+ {userStats.gaveUp} who gave up)</Typography>
            </div>

            {
                (isCreating)
                    ? (<NewUserForm active={isCreating} setActive={setIsCreating} />)
                    : null
            }
            
            {
                (user.rank == 'admin')
                    ? (
                        <div>
                            <Button variant="contained" onClick={() => { setIsCreating(true); } }>Create user</Button>
                            <Button variant="contained">Import users</Button>
                        </div>
                    )
                    : null
            }

            <UsersList 
                title="Users" 
                list={users} 
                showAccountType
            />
        </div>
    </>;
}

export default Users;