import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';
import Context, { userContext } from './Context';
import Homepage from './pages/Homepage';
import Users from './pages/Users';
import CssBaseline from '@mui/material/CssBaseline';
import './App.css';
import Interviews from './pages/Interviews';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Phases from './pages/Phases';
import { useContext, useState } from 'react';
import Recruits from './pages/Recruits';
import Teams from './pages/Teams';
import ViewUser from './pages/ViewIndividual/ViewUser';
import ViewInterview from './pages/ViewIndividual/ViewInterview';
import ViewTeam from './pages/ViewIndividual/ViewTeam';
import SiteContext, { siteContext } from './components/Utils/SiteContext';
import Login from './pages/Login';
import Unauthorized from './pages/BadRequest/Unauthorized';
import InConstruction from './pages/BadRequest/InConstruction';
import Logs from './pages/Logs';
import Decisions from './pages/Decisions';

function App() {
  const { user, isLoading } = useContext(siteContext);

  if(isLoading) {
    return (<h1>Loading...</h1>);
  }

  if(!user) {
    return (<Login />);
  }

  if(user.rank != 'siscot' && user.rank != 'admin') {
    return (<Unauthorized />);
  }

  return (
    <Context>
      <LocalizationProvider dateAdapter={AdapterDayjs} >
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Homepage />} />
            <Route exact path="/users" element={<Users />} />
            <Route path="/viewUser" element={<ViewUser />} />
            <Route exact path="/interviews" element={<Interviews />} />
            <Route path="/viewInterview" element={<ViewInterview />} />
            <Route exact path="/phases" element={<Phases />} />
            <Route exact path="/feedback" element={<Recruits />} />
            <Route exact path="/teams" element={<Teams />} />
            <Route path="/viewTeam" element={<ViewTeam />} />
            <Route exact path="/logs" element={<Logs />} />
            <Route exact path="/statistics" element={<InConstruction />} />
            <Route exact path="/decisions" element={<Decisions />} />
          </Routes>
        </BrowserRouter>
      </LocalizationProvider>
    </Context>
  );
}

export default App;
