import {
  Button,
  Avatar,
  Box,
  Divider,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { BACKEND_URL } from "../../Context";
import { useNavigate } from "react-router-dom";
import "./FeedbackMessage.css";
import { Delete, Reply } from "@mui/icons-material";
import { FeedbackApi, PhaseApi, UserApi } from "../../Utils";
import ResultToast from "../ResultToast";
import { siteContext } from "../Utils/SiteContext";

function FeedbackMessage({
  recruit,
  feedback,
  handleReply,
  handleDelete,
  questions,
}) {
  const { user } = useContext(siteContext);
  const navigate = useNavigate();
  const [sender, setSender] = useState();
  const [reply, setReply] = useState();
  const [phase, setPhase] = useState();
  const [isOpenQuestions, setOpenQuestions] = useState(false);

  useEffect(() => {
    const loadSender = async () => {
      setSender(await UserApi.getUser(feedback.senderId));
    };

    const loadPhase = async () => {
      if (feedback.phaseId) {
        setPhase(await PhaseApi.getPhase(feedback.phaseId));
      } else {
        setPhase({ name: "None" });
      }
    };

    loadSender();
    loadPhase();
  }, [feedback]);

  if (!feedback || !sender || !recruit || !phase) return;

  return (
    <Paper className="feedback-message" sx={{ width: "90%", borderRadius: 8 }}>
      <div className="feedback-message-header">
        <div className="feedback-message-header-info">
          <Tooltip title={`${sender.firstName} ${sender.lastName}`}>
            <IconButton onClick={() => navigate(`/viewUser?id=${sender.id}`)}>
              <Avatar
                id={`feedback-avatar-${sender.id}`}
                alt={`Avatar of ${sender.firstName} ${sender.lastName}`}
                src={`${BACKEND_URL}/api/user/${sender.id}/avatar`}
                sx={{ width: 24, height: 24 }}
              />
            </IconButton>
          </Tooltip>
          <Typography variant="h7" className="feedback-message-sender">
            {sender.firstName} {sender.lastName}
          </Typography>
          <Typography variant="body2" className="feedback-message-phase">
            (Phase: <i>{phase.name}</i>)
          </Typography>
        </div>
        <div className="questions-and-delete-container">
          {questions && feedback.parent == null ? (
            <Button
              size="small"
              onClick={() => {
                setOpenQuestions(!isOpenQuestions);
              }}
            >
              questions
            </Button>
          ) : null}
          {user.rank == "admin" || user.id == sender.id ? (
            <div>
              <Tooltip title="Delete">
                <IconButton
                  onClick={() => handleDelete(feedback)}
                  color="error"
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </div>
          ) : null}
        </div>
      </div>
      {questions && isOpenQuestions ? (
        <p className="questions">{questions}</p>
      ) : null}
      <div className="feedback-message-content">
        <Typography variant="body1" whiteSpace="break-spaces">
          {feedback.content}
        </Typography>
        {feedback.parent == null ? (
          <div className="feedback-message-reply">
            <TextField
              id="standard-basic"
              variant="standard"
              size="small"
              placeholder="Reply"
              className="feedback-message-text"
              value={reply}
              onChange={(e) => setReply(e.target.value)}
            />
            <Tooltip title="Reply">
              <IconButton
                onClick={() => {
                  handleReply(feedback, reply);
                  setReply("");
                }}
              >
                <Reply />
              </IconButton>
            </Tooltip>
          </div>
        ) : null}
      </div>
      {feedback.children && feedback.children.length != 0 ? (
        <div className="feedback-message-comments">
          {feedback.children.map((child) => (
            <FeedbackMessage
              key={child.id}
              feedback={child}
              recruit={recruit}
              handleDelete={handleDelete}
            />
          ))}
        </div>
      ) : null}
    </Paper>
  );
}

export default FeedbackMessage;
