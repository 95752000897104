import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import { BACKEND_URL } from "../../Context";
import { useEffect, useState } from "react";
import { FeedbackApi, PhaseApi } from "../../Utils";
import FeedbackMessage from "../Feedback/FeedbackMessage";

function UserPreview({ user }) {
    const [ phases, setPhases ] = useState([]);
    const [ feedbackList, setFeedbackList ] = useState([]);

    useEffect(() => {
        const loadPhases = async () => {
            const list = await PhaseApi.getAll();
            setPhases(list);
        };
      
        const loadFeedbackTree = async () => {
            try {
              await loadPhases();
              const list = await FeedbackApi.getUserFeedback(user);
              setFeedbackList(list);
            } catch (error) {
              console.error(error);
            }
        };
      
        if(user.rank == 'recruit')
            loadFeedbackTree();
    }, [ user ]);

    const recruitFeedback = (user.rank == 'recruit')
        ? (
            <div id="feedback-cards">
                {
                    feedbackList.map((elem) => (
                        <FeedbackMessage
                            key={elem.id}
                            feedback={elem}
                            recruit={user}
                            handleDelete={() => {}}
                            handleReply={() => {}}
                            questions={
                                phases.find((phase) => phase.id == elem.phaseId)?.questions
                            }
                        />
                    ))
                }
            </div>
        )
        : null;

    const finalDecision = 
        (user?.recruit?.firstOptionDecision)
            ? user?.recruit?.firstOption
            : (user?.recruit?.secondOptionDecision)
                ? user?.recruit?.secondOption
                : null;

    return (
        <Card className="user-preview-card">
            <CardMedia
                component="img"
                image={`${BACKEND_URL}/api/user/${user.id}/avatar`}
                title="User avatar"
            />
            <CardContent sx={{ width: '98%' }}>
                <Typography gutterBottom variant="h5" component="div">
                    {user.firstName} {user.lastName}
                </Typography>
                {
                    (user?.recruit?.firstOption)
                        ? (
                            <Typography gutterBottom variant="h6" component="div">
                                Options: &nbsp;
                                    <strong>{user?.recruit?.firstOption}</strong>, &nbsp; 
                                    <strong>{user?.recruit?.secondOption}</strong>
                            </Typography>
                        ) : null
                }
                {
                    (finalDecision)
                        ? (
                            <Typography gutterBottom variant="h6" component="div">
                                Final decision: <strong>{finalDecision}</strong>
                            </Typography>
                        ) : null
                }
                {recruitFeedback}
            </CardContent>
        </Card>
    );

}

export default UserPreview;
