import { createContext, useContext, useEffect, useRef, useState } from "react";
import ResultToast from "../ResultToast";
import { ThemeProvider } from "@emotion/react";
import { Button, createTheme, CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Typography } from "@mui/material";
import { UserApi } from "../../Utils";
import { DarkMode } from "@mui/icons-material";
import { CookiesProvider, useCookies } from "react-cookie";
import { BrowserRouter, useNavigate } from "react-router-dom";

export const siteContext = createContext({ });
export default function SiteContext({ children }) {
    const [ cookies, setCookie, removeCookie ] = useCookies();

    const [ user, setUser ] = useState();
    const [ isLoading, setIsLoading ] = useState(true);
    const [ alertMessage, setAlertMessage ] = useState();
    const [ alertType, setAlertType ] = useState("success");

    const [ confirmModal, setConfirmModal ] = useState({
        open: false,
        text: "",
    });

    const confirmModalResolve = useRef();

    const theme = createTheme({ palette: { mode: (cookies.theme) ? cookies.theme : 'light' }});

    const handleLogout = async () => {
        await UserApi.logout();
        setUser(null);

        setAlertMessage("Logged out.");
        setAlertType("success");

        window.location.reload();
    };

    useEffect(() => {
        const loadUser = async () => {
            try {
                const data = await UserApi.getUser();
                setUser(data);
            } catch (error) {
                console.error(error.message);
            }

            setIsLoading(false);
        }

        loadUser();
    }, []);

    return (<>
        <CookiesProvider defaultSetOptions={{ path: '/' }}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <ResultToast message={alertMessage} setMessage={setAlertMessage} type={alertType} />
                <siteContext.Provider value={{
                    user: user,
                    logout: handleLogout,
                    isLoading: isLoading,
                    toastUpdateFlag: alertMessage,
                    cookies: cookies,
                    setCookie: setCookie,
                    theme: cookies.theme,
                    themeObject: theme,
                    setTheme: async (newTheme) => setCookie('theme', newTheme),
                    toggleTheme: async () => setCookie(
                        'theme', 
                        (!cookies.theme || cookies.theme == 'light')
                            ? 'dark'
                            : 'light' 
                    ),
                    errorToast: async (message) => {
                        setAlertMessage(message);
                        setAlertType("error");
                    },
                    successToast: async (message) => {
                        setAlertMessage(message);
                        setAlertType("success")
                    },
                    requireConfirm: async ({ message, title, refuseText, confirmText }) => {
                        setConfirmModal({
                            open: true,
                            text: message,
                            refuseText: refuseText,
                            confirmText: confirmText,
                            title: title
                        });

                        return new Promise((res, rej) => {
                            confirmModalResolve.current = res;
                        });
                    }
                }}>
                    <Dialog open={confirmModal.open}>
                        <DialogTitle sx={{ m: 0, p: 2 }}>
                            {confirmModal.title ? confirmModal.title : "Confirm action?"}
                        </DialogTitle>
                        <DialogContent>
                            <Typography gutterBottom>
                                {confirmModal.text}
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                confirmModalResolve.current(true);
                                setConfirmModal({
                                    open: false,
                                    text: ""
                                })
                            }}>
                                {confirmModal.confirmText ? confirmModal.confirmText : "Confirm"}
                            </Button>
                            <Button autoFocus onClick={() => {
                                confirmModalResolve.current(false);
                                setConfirmModal({
                                    open: false,
                                    text: "",
                                });
                            }}>
                                {confirmModal.refuseText ? confirmModal.refuseText : "Cancel"}
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {children}
                </siteContext.Provider>
            </ThemeProvider>
        </CookiesProvider>
    </>);
}
