import { useContext } from "react";
import { BACKEND_URL } from "./Context";
import { createTheme } from "@mui/material";

export const VoteApi = {
    voteForRecruit: async (user, recruit, option, value) => {
        const userId = (typeof user === 'number') ? user : user.id;
        const recruitId = (typeof recruit === 'number') ? recruit : recruit.id;
        return fetch(`${BACKEND_URL}/api/vote`, {
            credentials: 'include',
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                voterId: userId,
                recruitId: recruitId,
                option: option,
                value: value
            })
        });
    },

    getVotesForUser: async (user, option) => {
        const userId = (typeof user === 'number') ? user : user.id;
        const req = await fetch(`${BACKEND_URL}/api/user/${userId}/votes`, {
            credentials: 'include'
        });
        const res = await req.json();
        if(option)
            return res.filter((vote) => vote.option == option);
        else
            return res;
    }
}

export const AnnouncementsApi = {
    postNew: async (user, message) => {
        const userId = (typeof user === 'number') ? user : user.id;
        return fetch(`${BACKEND_URL}/api/announcement`, { 
            credentials: 'include',
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                author: userId,
                message: message
            })
        });
    }
}

export const PostLog = async (user, type, message) => {
    const userId = (typeof user === 'number') ? user : user.id;
    return fetch(`${BACKEND_URL}/api/logs`, { 
        credentials: 'include',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            user: userId,
            type: type,
            message: message
        })
    });
}

export const GetLogs = async () => {
    const req = await fetch(`${BACKEND_URL}/api/logs`, { credentials: 'include' });
    return req.json();
}

export const InterviewApi = {
    getAll: async () => {
        const req = await fetch(`${BACKEND_URL}/api/interview`, { credentials: 'include' });
        return req.json();
    },

    getInterview: async (id) => {
        const req = await fetch(`${BACKEND_URL}/api/interview/${id}`, { credentials: 'include' });
        return req.json();
    },

    createInterview: async (date, slots) => {
        return fetch(`${BACKEND_URL}/api/interview`, {
            credentials: 'include',
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                date: date.toString(),
                slots: slots
            })
        });
    }
};

export const TeamsApi = {
    getAllTeams: async () => {
        const req = await fetch(`${BACKEND_URL}/api/team`, { credentials: 'include' });
        return req.json();
    },

    getTeam: async (id) => {
        const req = await fetch(`${BACKEND_URL}/api/team/${id}`, { credentials: 'include' });
        return req.json();
    },

    generateNewTeams: async (firstOptionWeight, secondOptionWeight, teamSizeWeight) => {
        return fetch(`${BACKEND_URL}/api/team`, {
            credentials: 'include',
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                firstOptionWeight: firstOptionWeight,
                secondOptionWeight: secondOptionWeight,
                teamSizeWeight: teamSizeWeight
            })
        });
    }
};

export const PhaseApi = {
    getCurrentPhase: async () => {
        const req = await fetch(`${BACKEND_URL}/api/phase`, { credentials: 'include' });
        if(req.status === 200) {
            return req.json();
        } else {
            return null;
        }
    },

    getAll: async () => {
        const req = await fetch(`${BACKEND_URL}/api/phase/all`, { credentials: 'include' });
        return req.json();
    },

    getPhase: async (id) => {
        const req = await fetch(`${BACKEND_URL}/api/phase/${id}`, { credentials: 'include' });
        return req.json();
    },

    createPhase: async (name, startDate, endDate, questions, canRegister, canScheduleInterview) => {
        return fetch(`${BACKEND_URL}/api/phase`, {
            credentials: 'include',
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                name: name,
                startDate: startDate.toString(),
                endDate: endDate.toString(),
                questions: questions,
                canRegister: canRegister,
                canScheduleInterview: canScheduleInterview
            })
        });
    }
};

export const UserApi = {
    getUser: async (id) => {
        if(!id) {
            const req = await fetch(`${BACKEND_URL}/api/user`, { credentials: 'include' });
            return req.json();
        }

        const req = await fetch(`${BACKEND_URL}/api/user/${id}`, { credentials: 'include' });
        return req.json();
    },

    getAllUsers: async () => {
        const req = await fetch(`${BACKEND_URL}/api/user/all`, { credentials: 'include' });
        return req.json();
    },

    getAllRecruits: async (includeInactive = true) => {
        const req = await fetch(`${BACKEND_URL}/api/user/recruits`, { credentials: 'include' });
        const res = await req.json();
        return includeInactive ? res : res.filter((user) => user.rank !== 'recruit_lasat');
    },

    deleteUser: async (id) => {
        return fetch(`${BACKEND_URL}/api/user/${id}`, {
            credentials: 'include',
            method: 'DELETE'
        });
    },

    createUser: async (firstName, lastName, email, rank) => {
        return fetch(`${BACKEND_URL}/api/user`, {
            credentials: 'include',
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                firstName: firstName,
                lastName: lastName,
                email: email,
                rank: rank
            })
        });
    },

    updateUser: async (id, data) => {
        console.log(data);
        return fetch(`${BACKEND_URL}/api/user/${id}`, {
            credentials: 'include',
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });
    },

    logout: async () => {
        return fetch(`${BACKEND_URL}/api/google/logout`, { credentials: 'include' });
    },

    uploadAvatar: async (user, file) => {
        const userId = typeof user == 'number' ? user : user.id;
        const formData = new FormData();
        formData.append('avatar', file);
        return fetch(`${BACKEND_URL}/api/user/${userId}/avatar`, {
            credentials: 'include',
            method: 'POST',
            body: formData
        });
    }
};

export const FeedbackApi = {
    getUserFeedback: async (user) => {
        const id = (typeof user == 'number') ? user : user.id;
        const req = await fetch(`${BACKEND_URL}/api/user/${id}/feedback`, { credentials: 'include' });
        return req.json();
    },

    deleteById: async (id) => {
        return fetch(`${BACKEND_URL}/api/feedback/${id}`, {
            credentials: 'include',
            method: 'DELETE'
        });
    },

    createFeedback: async (parent, user, recruit, phaseId, message) => {
        const phase = (phaseId && phaseId !== 'none')
                        ? await PhaseApi.getPhase(phaseId)
                        : await PhaseApi.getCurrentPhase();

        return fetch(`${BACKEND_URL}/api/feedback`, {
            credentials: 'include',
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                parent: (parent) ? ((typeof parent == 'number') ? parent : parent.id) : null,
                phaseId: (phase && phase?.name !== 'No phase') ? phase.id : null,
                senderId: (typeof user == 'number') ? user : user.id,
                recruitId: (typeof recruit == 'number') ? recruit : recruit.id,
                content: message
            })
        });
    }
};
