import { createContext, useContext, useEffect, useState } from "react";
import { siteContext } from "./SiteContext";
import { AnnouncementsApi, GetLogs, PhaseApi, UserApi } from "../../Utils";

export const dataContext = createContext({});
export default function DataContext({ children }) {
    const [ logs, setLogs ] = useState([]);
    const [ phases, setPhases ] = useState([]);
    const [ currentPhase, setCurrentPhase ] = useState();
    const [ allUsers, setAllUsers ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(true);

    useEffect(() => {
        const loadData = async () => {
            setLogs(await GetLogs());
            setPhases(await PhaseApi.getAll());
            setCurrentPhase(await PhaseApi.getCurrentPhase());
            setAllUsers(await UserApi.getAllUsers());
            setIsLoading(false);
        };

        if(isLoading)
            loadData();
    }, []);

    return (<>
        <dataContext.Provider value={{
            logs: logs,
            users: allUsers,
            admins: allUsers.filter((user) => user.rank === 'admin'),
            members: allUsers.filter((user) => user.rank === 'member'),
            recruits: allUsers.filter((user) => user.rank === 'recruit' || user.rank === 'recruit_lasat'),
            activeRecruits: allUsers.filter((user) => user.rank === 'recruit'),
            phases: phases,
            currentPhase: currentPhase
        }}>
            {children}
        </dataContext.Provider>
    </>);
}