import { Class, Gavel, Group, LooksOne, LooksTwo, Schedule, School, Event, ThumbUpSharp, Help, ThumbDownSharp, Pending } from "@mui/icons-material";
import { Avatar, Card, CardContent, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { BACKEND_URL } from "../../Context";
import { TimeClock } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { VoteApi } from "../../Utils";

function RecruitCard({ user }) {
    const [ userTeam, setUserTeam ] = useState(null);
    const [ userInterview, setUserInterview ] = useState(null);
    const [ firstOptVotes, setFirstOptVotes ] = useState([]);
    const [ secondOptVotes, setSecondOptVotes ] = useState([]);

    const firstOpt = {
        yes:   firstOptVotes.filter((vote) => vote.value == 'verde').length,
        maybe: firstOptVotes.filter((vote) => vote.value == 'galben').length,
        no:    firstOptVotes.filter((vote) => vote.value == 'rosu').length
    };

    const secondOpt = {
        yes:   secondOptVotes.filter((vote) => vote.value == 'verde').length,
        maybe: secondOptVotes.filter((vote) => vote.value == 'galben').length,
        no:    secondOptVotes.filter((vote) => vote.value == 'rosu').length
    };

    useEffect(() => {
        const loadUserTeam = async () => {
            try {
                const req = await fetch(`${BACKEND_URL}/api/team/${user.recruit.teamId}`, { credentials: 'include' });
                const team = await req.json();
                setUserTeam(team);
            } catch(error) {
                console.error(error.message);
            }
        };

        const loadUserInterview = async () => {
            try {
                const req = await fetch(`${BACKEND_URL}/api/interview/${user.recruit.interviewId}`, { credentials: 'include' });
                const interview = await req.json();
                if(interview.date)
                    setUserInterview(interview);
                else
                    setUserInterview(null);
            } catch(error) {
                console.error(error.message);
            }
        };

        const loadUserVotes = async () => {
            try {
                setFirstOptVotes(await VoteApi.getVotesForUser(user, 1));
                setSecondOptVotes(await VoteApi.getVotesForUser(user, 2));
            } catch(error) {
                console.error(error.message);
            }
        };

        loadUserTeam();
        loadUserVotes();
        loadUserInterview();
    }, [ user ]);

    const getDecisionIcon = (decision) => {
        if(decision == 'verde')
            return <ThumbUpSharp color="success" />;
        else if(decision == 'galben')
            return <Help color="warning" />;
        else if(decision == 'rosu')
            return <ThumbDownSharp color="error" />
        else
            return <Pending />;
    };

    return (
        <Card sx={{ width: '45%', height: '900px' }} className="view-user-card">
            <CardContent>
                <Typography variant="h5">Recruit data</Typography>
                <List style={{ width: '100%' }} aria-label="recruit-info">
                    <ListItem>
                        <ListItemAvatar color="error">
                            <Avatar>
                                {getDecisionIcon(user?.recruit?.firstOptionDecision)}
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText 
                            primary={`${user?.recruit?.firstOption}`}
                            secondary={user?.recruit?.firstOptionDecision ? user?.recruit?.firstOptionDecision : `Pending`}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar>
                                {getDecisionIcon(user?.recruit?.secondOptionDecision)}
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText 
                            primary={`${user?.recruit?.secondOption}`}
                            secondary={user?.recruit?.secondOptionDecision ? user?.recruit?.secondOptionDecision : `Pending`}
                        />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemAvatar><Avatar><Event /></Avatar></ListItemAvatar>
                        <ListItemText primary="Birthday" secondary={user.birthday ? dayjs(user.birthday).format(`LLLL`) : 'Unknown'} />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemAvatar><Avatar><Schedule /></Avatar></ListItemAvatar>
                        <ListItemText primary="Programmed interview" secondary={
                            (userInterview)
                                ? dayjs(userInterview.date).format(`dddd, DD MMMM YYYY (HH:mm)`)
                                : `Not scheduled    `
                        }/>
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar><Avatar><Group /></Avatar></ListItemAvatar>
                        <ListItemText primary="Team" secondary={(userTeam) ? userTeam.name : 'Not assigned'} />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar><Avatar><LooksOne /></Avatar></ListItemAvatar>
                        <ListItemText 
                            primary="First option" 
                            secondary={
                                (user.recruit.firstOption) 
                                    ? `${user.recruit.firstOption} (V: ${firstOpt.yes}, G: ${firstOpt.maybe}, R: ${firstOpt.rosu}) `
                                    : 'Not selected'
                            } 
                            secondaryTypographyProps={
                                user?.recruit?.lockedIn ? {
                                    color: 'green',
                                    fontWeight: 'bold'
                                } : null
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar><Avatar><LooksTwo /></Avatar></ListItemAvatar>
                        <ListItemText 
                            primary="Second option" 
                            secondary={
                                (user.recruit.secondOption)
                                    ? `${user.recruit.secondOption} (V: ${secondOpt.yes}, G: ${secondOpt.maybe}, R: ${secondOpt.no})`
                                    : 'Not selected'
                            } 
                            secondaryTypographyProps={
                                user?.recruit?.lockedIn ? {
                                    color: 'green',
                                    fontWeight: 'bold'
                                } : null
                            }
                        />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemAvatar><Avatar><Class /></Avatar></ListItemAvatar>
                        <ListItemText primary="First option homework" secondary={
                            (user.recruit.firstOptHomework)
                                ? 'Uploaded'
                                : 'Not uploaded'
                        }/>
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar><Avatar><Class /></Avatar></ListItemAvatar>
                        <ListItemText primary="Second option homework" secondary={
                            (user.recruit.secondOptHomework)
                                ? 'Uploaded'
                                : 'Not uploaded'
                        }/>
                    </ListItem>
                </List>
            </CardContent>
        </Card>
    )
}

export default RecruitCard;