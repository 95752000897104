import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardMedia,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  styled,
  Typography,
} from "@mui/material";
import { BACKEND_URL, userContext } from "../../Context";
import {
  CloudUpload,
  Edit,
  Email,
  Facebook,
  Person,
  Phone,
  School,
} from "@mui/icons-material";
import { useContext, useState } from "react";
import { siteContext } from "../Utils/SiteContext";
import UpdateUserForm from "../Forms/UpdateUserForm";
import { PostLog, UserApi } from "../../Utils";
import axios from "axios";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function UserCard({ user }) {
  const [updateUser, setUpdateUser] = useState();
  const ctx = useContext(siteContext);
  const siteUser = ctx.user;
  const [isRecruitStatus, setRecruitStatus] = useState(user.rank);
  const uploadAvatar = async (e) => {
    const { files } = e.target;
    const res = await UserApi.uploadAvatar(user, files[0]);
    if (res.status === 200) {
      ctx.successToast("User avatar uploaded!");
      PostLog(
        ctx.user,
        "avatar_upload",
        `Incarcat poza utilizatorului "${user.firstName} ${user.lastName}".`
      );
      window.location.reload();

    } else {
      const msg = await res.text();

      ctx.errorToast("Failed to upload user avatar.");
      PostLog(ctx.user, "error", msg);
      console.error(msg);
    }
  };

  if (!siteUser || !user) {
    return <></>;
  }

  const toggleLasat = async () => {
    const newRank = user.rank == 'recruit' ? 'recruit_lasat' : 'recruit';
    const res = await UserApi.updateUser(user.id, { rank: newRank });

    if(res.status === 200) {
      ctx.successToast(`Utilizatorul a fost actualizat cu succes!`);
      PostLog(
        ctx.user, 
        "update_user", 
        `Actualizat utilizator "${user.firstName} ${user.lastName}": rank = "${newRank}"`
      );
      window.location.reload();
    } else {
      ctx.errorToast(`Nu s-a putut actualiza utilizatorul.`);
    }
  }

  return (
    <>
      <UpdateUserForm
        active={updateUser}
        setActive={setUpdateUser}
        target={user}
      />
      <Card
        sx={{ width: "45%", height: "900px" }}
        className="view-user-card card-user"
      >
        <CardMedia
          component="img"
          sx={{ marginTop: 4, height: "50%", objectFit: "contain" }}
          image={`${BACKEND_URL}/api/user/${user.id}/avatar`}
          title="User avatar"
        />

        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {user.firstName} {user.lastName}
            {user.token ? ` (${user.token})` : null}
          </Typography>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "8px",
            }}
          >
            <Button
              component="label"
              variant="contained"
              size="small"
              startIcon={<CloudUpload />}
            >
              Change avatar
              <VisuallyHiddenInput
                type="file"
                accept="image/jpeg image/png"
                onChange={uploadAvatar}
              />
            </Button>
            {user.id == ctx.user.id || ctx.user.rank == "admin" ? (
              <Button
                component="label"
                variant="contained"
                size="small"
                startIcon={<Edit />}
                onClick={() => setUpdateUser(true)}
              >
                Update profile
              </Button>
            ) : null}

            <Button
              component="label"
              variant="contained"
              size="small"
              startIcon={<Facebook />}
              onClick={() => {
                window.location.href = user.facebook;
              }}
            >
              Facebook
            </Button>
          </div>

          {(ctx.user.rank == "admin" && (user.rank == 'recruit' || user.rank == 'recruit_lasat')) ? (
            <Button
              variant={user.rank == 'recruit' ? "outlined" : "contained"}
              color="error"
              style={{ marginTop: 10 }}
              onClick={toggleLasat}
            >
              Lăsat
            </Button>
          ) : null}

          <List style={{ width: "100%" }} aria-label="user-info">
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <Email />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Email" secondary={user.email} />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <Phone />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Phone"
                secondary={user.phone ? user.phone : "Not set"}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <Person />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="User type"
                secondaryTypographyProps={user.rank === 'recruit_lasat' ? {
                  color: 'red',
                  fontWeight: 'bold'
                } : null}
                secondary={

                  user.rank == "admin"
                    ? "Administrator"
                    : user.rank == "siscot"
                      ? "Member"
                      : user.rank == 'recruit'
                        ? "Recruit"
                        : user.rank == 'recruit_lasat'
                          ? "Nu mai face parte din procesul de recrutare"
                          : "Inactive"
                }
              />
            </ListItem>
            {user.rank == "recruit" || user.rank == "recruit_lasat" ? (
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <School />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="School info"
                  secondary={`${user.recruit.university}, ${user.recruit.faculty} - ${user.recruit.studyDomain} (Year ${user.recruit.studyYear})`}
                />
              </ListItem>
            ) : null}
          </List>
        </CardContent>
      </Card>
    </>
  );
}

export default UserCard;
