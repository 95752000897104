import { useSearchParams } from "react-router-dom";
import Navbar from "../../components/Navigation/Navbar";
import { useEffect, useState } from "react";
import { BACKEND_URL } from "../../Context";
import { Card, CardContent, CardMedia, Divider, List, ListItem, ListItemText, Typography } from "@mui/material";
import "./ViewUser.css";    
import { AspectRatio } from "@mui/icons-material";
import UserCard from "../../components/UserInfo/UserCard";
import RecruitCard from "../../components/UserInfo/RecruitCard";
import FeedbackCard from "../../components/UserInfo/FeedbackCard";
import { UserApi } from "../../Utils";

function ViewUser() {
    const [ searchParams ] = useSearchParams();
    const userId = searchParams.get(`id`);

    const [ viewedUser, setViewedUser ] = useState();

    useEffect(() => {
        const loadSelected = async () => {
            try {
                const user = await UserApi.getUser(userId);
                setViewedUser(user);
            } catch(error) {
                console.error(error.message);
            }
        };

        loadSelected();
    }, [userId]);

    if(!viewedUser)
        return (<Navbar />);

    return <>
        <Navbar />
        <div id="view-user-page">
            <div id="view-user-cards">
                <UserCard user={viewedUser} />
                {
                    (viewedUser.rank == 'recruit' || viewedUser.rank == 'recruit_lasat')
                        ? <RecruitCard user={viewedUser} />
                        : null
                }
            </div>
            
            {
                (viewedUser.rank == 'recruit' || viewedUser.rank == 'recruit_lasat')
                    ? <FeedbackCard recruit={viewedUser} />
                    : null
            }
        </div>
    </>;
}

export default ViewUser;