import { Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { BACKEND_URL, userContext } from "../../Context";
import "./QuickFeedback.css";
import { FeedbackApi, PhaseApi, UserApi } from "../../Utils";
import { siteContext } from "../Utils/SiteContext";

function QuickFeedback() {
    const { user, successToast, errorToast } = useContext(siteContext);
    const [ users, setUsers ] = useState([]);
    const [ selected, setSelected ] = useState();
    const [ feedback, setFeedback ] = useState('');
    const [ phases, setPhases ] = useState([]);
    const [ currentPhase, setCurrentPhase ] = useState();
    const [ selectedPhase, setSelectedPhase ] = useState();

    const handleSubmit = async () => {
        const req = await FeedbackApi.createFeedback(null, user, selected, selectedPhase, feedback);

        if(req.status == 200) {
            successToast("Feedback posted.");
        }
        else {
            errorToast("Failed to post feedback.");
            console.error(await req.text());
        }

        setSelected(null);
        setFeedback('');
    };

    useEffect(() => {
        const loadRecruits = async () => {
            try {
                const list = await UserApi.getAllRecruits(false);
                console.log(list);
                setUsers(list);
                setSelected(list[0].id);
            } catch(error) {
                console.error(error.message);
            }
        };

        const loadPhases = async () => {
            try {
                const phases = await PhaseApi.getAll();
                console.log(phases);
                const current = await PhaseApi.getCurrentPhase();
                setPhases(phases);
                setCurrentPhase(current);
            } catch(error) {
                console.error(error.message);
            }
        };

        loadRecruits();
        loadPhases();
    }, []);

    if(users.length === 0)
        return <></>;

    return (<div className="quick-feedback">
        <Typography variant="h5">Quick feedback</Typography>
        <Typography variant="h6">
            <strong style={{color: 'red'}}>ATENTIE!</strong> Nu puneti emoji-uri in feedback 😔
        </Typography>
        <FormControl sx={{ m: 1, width: 300, gap: '16px' }}>
            <InputLabel id="quick-feedback-phase">Phase</InputLabel>
            <Select
                reqiured
                labelId="quick-feedback-phase"
                value={selectedPhase}
                defaultValue={currentPhase?.id ? currentPhase.id : 'none'}
                onChange={(e) => setSelectedPhase(e.target.value)}
                input={<OutlinedInput label="Phase" />}
                MenuProps={{ PaperProps: { style: { maxHeight: 48 * 4.5 + 8, width: 250 } }}}
            >
                <MenuItem key='none' value='none'>
                    None
                </MenuItem>
                {
                    phases.map((phase) => (
                        <MenuItem key={phase.id} value={phase.id}>
                            {
                                (currentPhase && phase.id === currentPhase.id) 
                                    ? `Current: ${phase.name}` 
                                    : phase.name
                            }
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
        <FormControl sx={{ m: 1, width: 300, gap: '16px' }} >
            <InputLabel id="quick-feedback-search">Recruit</InputLabel>
            <Select
                required
                labelId="quick-feedback-search"
                value={selected}
                defaultValue={users[0]?.id}
                onChange={(e) => setSelected(e.target.value)}
                input={<OutlinedInput label="Recruit" />}
                MenuProps={{ PaperProps: { style: { maxHeight: 48 * 4.5 + 8, width: 250 } }}}
            >
                {
                    users.map((user) => (
                        <MenuItem key={user.id} value={user.id}>
                            {user.firstName} {user.lastName}
                        </MenuItem>
                    ))
                }
            </Select>

            <TextField 
                required 
                type="text" 
                variant="outlined" 
                label="Feedback"
                value={feedback.replace(/(\\n)/g, "\n")}
                onChange={(e) => setFeedback(e.target.value)}
                multiline
                minRows={10}
            />

            <Button variant="contained" onClick={handleSubmit}>Submit</Button>
        </FormControl>
    </div>);
}

export default QuickFeedback;
